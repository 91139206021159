import to from 'await-to-js'
import axios from 'axios'
import axiosRetry from 'axios-retry'

import {
  IS_ENV_SSR,
  RETRY_COUNT,
  SEARCH_FRONT_SERVER_ENDPOINT,
} from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import type { AppType, UserType } from '../../_app/context/FoundationProvider'
import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeRequestIdPlugin,
  plantaeUserAgentPlugin,
  plantaeKarrotSessionIdPlugin,
  plantaeCommonHeadersPlugin,
  plantaeSearchOriginPlugin,
  plantaeSearchWebVersionPlugin,
  plantaeInjectServerSideHeaderPlugin,
} from '../../plantae/plugins'

import plantaeSearchUserIdPlugin from '../../plantae/plugins/plantaeSearchUserIdPlugin'
import { KeywordApi } from '../../__codegen__/__openapi__/search-front-server'
import type { SearchExperimentReferrerType } from '../../referrer/types'
import {
  addExperimentXSearchHeader,
  addFunnelFromXSearchHeader,
} from '../../_app/utils/addCustomHeader'
import type { FunnelFromIdTypes } from '../../referrer/constants'
import type { NodeOnlySSRBypassHeadersType } from '../../_app/context/NodeOnlySSRBypassHeaderProvider'

export type ServiceHotKeywordsType = ReturnType<typeof ServiceHotKeywords>

export function getServiceHotKeywords({
  app,
  user,
  __NODE_ONLY_bypassHeaders,
}: {
  app: AppType
  user: UserType
  __NODE_ONLY_bypassHeaders?: NodeOnlySSRBypassHeadersType
}) {
  return ServiceHotKeywords({
    baseUrl: SEARCH_FRONT_SERVER_ENDPOINT,
    app,
    user,
    __NODE_ONLY_bypassHeaders,
  })
}

const ServiceHotKeywords = ({
  baseUrl,
  app,
  user,
  __NODE_ONLY_bypassHeaders,
}: {
  baseUrl: string
  app: AppType
  user: UserType
  __NODE_ONLY_bypassHeaders?: NodeOnlySSRBypassHeadersType
}) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }
  const axiosInstance = axios.create({
    headers,
  })

  daangnAxiosInterceptors({
    client: axiosInstance,
    plugins: IS_ENV_SSR
      ? [plantaeInjectServerSideHeaderPlugin({ __NODE_ONLY_bypassHeaders })]
      : [
          plantaeAuthPlugin({ fallbackAuthToken: user.authToken }),
          plantaeRequestIdPlugin(),
          plantaeKarrotSessionIdPlugin({ app }),
          plantaeUserAgentPlugin({
            userAgent: app.userAgent,
            os: app.os,
            version: app.version,
          }),
          plantaeSearchUserIdPlugin({ userId: user.id }),
          plantaeCommonHeadersPlugin(),
          plantaeSearchOriginPlugin(),
          plantaeSearchWebVersionPlugin(),
        ],
  })

  axiosRetry(axiosInstance, {
    retries: RETRY_COUNT,
    retryDelay: () => 0,
    retryCondition: () => true,
    shouldResetTimeout: true,
  })

  const client = new KeywordApi(undefined, baseUrl, axiosInstance)

  return {
    /**
     * 인기 검색어 목록
     */
    async getHotKeywords({
      regionId,
      referrer,
    }: {
      regionId: number
      referrer: {
        funnelFromId: FunnelFromIdTypes
        experiment: SearchExperimentReferrerType
      }
    }) {
      const [error, response] = await to(
        client.apiV1KeywordHotKeywordsGet(
          {
            regionId: String(regionId),
            pageSize: 10,
          },
          {
            headers: {
              ...addFunnelFromXSearchHeader({
                funnelFromId: referrer.funnelFromId,
              }),
              ...addExperimentXSearchHeader(referrer.experiment),
            },
          }
        )
      )

      if (error) {
        captureException(error)
        return null
      }

      if (!response?.data) {
        return null
      }

      return {
        ...response.data,
      }
    },
  }
}
